import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import { User } from '@/api/models';
import { state, actions } from '@/store';

import { envConfig } from '@/config/envConfig';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: envConfig.env.BASE_URL,
    routes: routes(authGuard, guestGuard, adminGuard),
});

export default router;

router.beforeEach(async (to, from, next) => {
    if (state.token && !state.user) {
        try {
            const user = await User.getAuthUser();
            actions.setUser(user);
        } catch (error) {
            actions.resetAuth();
            await this.$router.push({ name: 'Login' });
        }
    }

    next();
});

function beforeEnter(routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
}

function authGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            return next({ name: 'Login' });
        }

        next();
    });
}

function guestGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            return next({ name: 'Backoffice' });
        }

        next();
    });
}

function adminGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token || !state.user || !state.user.data.is_admin) {
            return next({ name: 'Inside' });
        }

        next();
    });
}
