import Vue from 'vue';
import { toCookie, toLocal } from './storages';
import dayjs from 'dayjs';

let startDayFilter = () => {
    let today = dayjs();
    let limitDate = today.year() + '-' + '12-01';
    if (today.diff(limitDate, 'days') >= 0) {
        return today.add(1, 'years');
    }
    return today;
};

const store = {
    token: null,
    user: null,

    sidebarIsOpen: false,
    elSelected: false,
    subElSelected: false,

    sectionsIsExpanded: false,
    visibleRowsQtyInTable: 10,

    search: '',
    filters: {
        semester: {
            scope: 'com',
            //is_home: false,
            field: 'com_start_date',
            direction: 'ASC',
            from: startDayFilter().startOf('year').format('YYYY-MM-DD'),
            to: startDayFilter().endOf('year').format('YYYY-MM-DD'),
        },
    },
};

toCookie(store, {
    attrs: ['token'],
    days: 365,
});

toLocal(store, { attrs: ['token'] });

const state = Vue.observable(store);

const actions = {
    getSemesterFilters() {
        return store.filters.semester;
    },
    setSemesterFilter(son, v) {
        store.filters.semester[son] = v;
    },
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
    },
    getUser() {
        return state.user;
    },
    resetAuth() {
        state.token = null;
        state.user = null;
    },
    getElSelected() {
        return state.elSelected;
    },
    setElSelected(elSelected) {
        state.elSelected = elSelected;
    },
    getSubElSelected() {
        return state.subElSelected;
    },
    setSubElSelected(subElSelected) {
        state.subElSelected = subElSelected;
    },
    getSidebarIsOpen() {
        return state.sidebarIsOpen;
    },
    setSidebarIsOpen(sidebarIsOpen) {
        state.sidebarIsOpen = sidebarIsOpen;
    },
    getSectionsIsExpanded() {
        return state.sectionsIsExpanded;
    },
    setSectionsIsExpanded(sectionsIsExpanded) {
        state.sectionsIsExpanded = sectionsIsExpanded;
    },
    getVisibleRowsQtyInTable() {
        return state.visibleRowsQtyInTable;
    },
    getArea() {
        return state.area;
    },
    setArea(area) {
        state.area = area;
    },
    getCourse() {
        return state.course;
    },
    setCourse(course) {
        state.course = course;
    },
    setSearch(search) {
        state.search = search;
    },
};

export { state, actions };
