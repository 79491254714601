import Model from "../Model";

class Connection extends Model {
    buildUrl ({ param }) {
        return ['connections', param];
    }

    async getHpConnStatus(param) {
        return await this.request({
            method: 'GET',
            url: 'connections/get-hp-conn-status',
            data: param
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'connections/update',
            data: form
        });
    }
}

export default new Connection;