export default (authGuard, guestGuard, adminGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/pages/outside/Login'),
        },
        {
            path: '/send-reset-link',
            name: 'SendResetLink',
            component: () => import('@/pages/outside/SendResetLink'),
        },
        {
            path: '/password-reset/:token',
            name: 'PasswordReset',
            component: () => import('@/pages/outside/PasswordReset'),
        },
    ]),

    ...authGuard([
        {
            path: '/',
            name: 'Inside',
            component: () => import('@/pages/inside/Inside.vue'),
            redirect: { name: 'ExecutiveSummary' },
            children: [
                {
                    path: 'splash',
                    name: 'Splash',
                    component: () => import('@/pages/inside/sections/splash/Splash.vue'),
                },
                {
                    path: 'executive-summary',
                    name: 'ExecutiveSummary',
                    component: () => import('@/pages/inside/sections/dashboards/DashboardMain.vue'),
                },
                /* {
                    path: 'traffic',
                    name: 'Traffic',
                    component: () => import('@/pages/inside/sections/dashboards/DashboardTraffic.vue'),
                },*/
                {
                    path: 'area/:areaId',
                    name: 'Area',
                    component: () => import('@/pages/inside/sections/dashboards/DashboardCourses.vue'),
                },
                {
                    path: 'semester/:semesterId',
                    name: 'Semester',
                    component: () => import('@/pages/inside/sections/dashboards/DashboardSemestre.vue'),
                },
                {
                    path: 'selection/:courseId/:editionId/:semesterId?',
                    name: 'Selection',
                    component: () => import('@/pages/inside/sections/dashboards/DashboardSelection.vue'),
                },
            ],
        },
    ]),

    {
        path: '*',
        name: '404',
        redirect: { name: 'Inside' },
    },

    ...adminGuard([
        {
            path: '/backoffice',
            name: 'Backoffice',
            component: () => import('@/pages/inside/Inside.vue'),
            redirect: { name: 'BackAreas' },
            children: [
                {
                    path: 'areas',
                    name: 'BackAreas',
                    component: () => import('@/pages/inside/sections/areas/Areas.vue'),
                },
                {
                    path: 'areaAmounts',
                    name: 'BackAreaAmounts',
                    component: () => import('@/pages/inside/sections/areaAmounts/AreaAmount.vue'),
                },
                {
                    path: 'courses',
                    name: 'BackCourses',
                    component: () => import('@/pages/inside/sections/courses/Courses.vue'),
                },
                {
                    path: 'editions',
                    name: 'BackEditions',
                    component: () => import('@/pages/inside/sections/editions/Editions.vue'),
                },
                {
                    path: 'users',
                    name: 'BackUsers',
                    component: () => import('@/pages/inside/sections/users/Users.vue'),
                },
                {
                    path: 'connections',
                    name: 'BackConnections',
                    component: () => import('@/pages/inside/sections/connections/Connections.vue'),
                },
                {
                    path: 'hplists',
                    name: 'BackHpLists',
                    component: () => import('@/pages/inside/sections/hplists/HpLists.vue'),
                },
                {
                    path: 'notifications',
                    name: 'BackNotifications',
                    component: () => import('@/pages/inside/sections/notifications/Notications'),
                },
                {
                    path: 'configurations',
                    name: 'Configurations',
                    component: () => import('@/pages/inside/sections/configurations/Configurations')
                },
            ]
        }
    ])

];