export const flatten = args => {
    return args.reduce((acc, val) => acc.concat(val), []);
};
import { theme } from '../../tailwind.config.js';

export const colors = {
    leads: theme.extend.colors.leads,
    sql: theme.extend.colors.greenSql,
    sql_in_process: theme.extend.colors.blueSql,
    students: theme.extend.colors.clients,
    opportunities: theme.extend.colors.opportunities,
    opportunities_plus: theme.extend.colors.opportunitiesPlus,
};

export const URLJoin = args => {
    return args
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/\/$/, '')
        .replace(/^(.+):\//, '$1://')
        .replace(/^file:/, 'file:/')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
};
