/* eslint-disable no-console */
import { envConfig } from '@/config/envConfig';
import { Workbox } from 'workbox-window';

let wb = null;

if ('serviceWorker' in navigator) {
    wb = new Workbox(`${envConfig.env.BASE_URL}service-worker.js`);
    wb.addEventListener('controlling', () => {
        window.location.reload();
    });
    wb.register().then();
}
export default wb;
