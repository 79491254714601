import Model from '../Model';

class Semesters extends Model {
    buildUrl({ param }) {
        return ['semesters', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'semesters',
            data: form,
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'semesters/store',
            data: form,
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'semesters/update',
            data: form,
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'semesters/destroy',
            data: form,
        });
    }
}

export default new Semesters();
