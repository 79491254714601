// tailwind.config.js

const plugin = require('tailwindcss/plugin');

module.exports = {
    future: {
        removeDeprecatedGapUtilities: false,
        purgeLayersByDefault: true,
    },
    darkMode: false, // or 'media' or 'class'
    theme: {
        boxShadow: {
            modal: '0px 0px 10px 3px rgba(242,239,212,0.2), 0px 0px 10px 3px rgba(242,239,212,0.2)',
            sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
            '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
            inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
            none: 'none',
        },
        minWidth: {
            4: '4rem',
            5: '5rem',
            10: '10rem',
            0: '0',
            '1/4': '25%',
            '1/2': '50%',
            '3/4': '75%',
            full: '100%',
        },
        extend: {
            colors: {
                // BACKOFFICE
                'bo-light-html': '#CCCDD1',
                'bo-dark-html': '#202837',
                'bo-form': '#eee',
                'bo-form1': '#31333B',
                'bo-form2': '#667894',
                'bo-aside': '#343a40',
                'bo-aside-items-hover': '#494e54',
                'bo-aside-items-selected': '#ebecec',
                'bo-nav-header': '#69797f',
                'bo-table': '#918E8E',
                'bo-blue-cust': '#5C83EA',
                'bo-red': '#c94845',
                'bo-yellow': '#f6ca4e',
                'bo-thead': '#74798C',
                'smoke-900': 'rgba(0, 0, 0, 0.9)',
                'smoke-800': 'rgba(0, 0, 0, 0.75)',
                'smoke-600': 'rgba(0, 0, 0, 0.6)',
                smoke: 'rgba(0, 0, 0, 0.5)',
                'smoke-400': 'rgba(0, 0, 0, 0.4)',
                'smoke-200': 'rgba(0, 0, 0, 0.25)',
                'smoke-100': 'rgba(0, 0, 0, 0.1)',
                'bo-input': '#1a222f',
                'bo-total': '#676A6E',
                'bo-active': '#3DAEE9',
                buttonDark: '#1F2736',
                biselLight: '#323B4D',
                box2: '#283143',
                main2: '#0acffe',
                biselDark: '#121822',
                notification: '#283143',
                // END BACKOFFICE

                html: '#0089AA',
                body: '#121822',
                module: '#202837',
                box: '#293345',
                separator: '#1D3241',
                separator2: 'rgba(0, 0, 0, 0.2)',
                calendar: '#1a202c',
                main: '#0089AA',
                mainSecondary: '#009EC4',
                mainSelected: 'rgba(0, 137, 170, 0.2)',
                blueMain: '#4a6496',
                blueSql: '#5af2df',
                greenSql: '#24997E',
                secondary: '#79849A',
                text: '#F6F6F6',
                good: '#6DBF81',
                //regular: '#EFAE59',
                regular: '#f6ca4e',
                // bad: '#c94845',
                bad: '#c94845',
                edemRed: '#c94845',
                visits: '#939597',
                leads: '#76A9D5',
                subtitle: '#77AAD6',
                sql: '#5A9DEC',
                opportunities: '#a576ef',
                opportunitiesPlus: '#8958ff',
                clients: '#0089AA',
                clientsSecondary: '#003a48',
                clientsSelected: '#049DC3',
                iconBars: '#05576A',
                instagram: '#E4405F',
                facebook: '#3c5a99',
                twitter: '#00cfd6',
                barsInformation: '#2473A5',
                barsRegistration: '#0BCEFC',

                background: '#121822',
                upper: '#222B3B',
                bisel: '#4b506b',
                biselUpperUp: 'rgba(0,0,0,.15)',
                biselUpperDown: '#263143',
                fontSecondary: '#79849A',
                aux: '#B9B9B9',
                shape: '#2E384A',
                time: '#5fe6c1',
                input: '#1a222f',
                golden: '#ffc591',
                btn: '#7298FF',
                danger: '#FF5E62',
                success: '#1DD8A1',
                gold: '#FFC591',
                alternate: '#5FE6C1',
                lila: '#665ccd',
            },
            width: {
                // BACKOFFICE
                '2px': '2px',
                '5por': '5%',
                '10por': '10%',
                '15por': '15%',
                '20por': '20%',
                '25por': '25%',
                '30por': '30%',
                '35por': '35%',
                '40por': '40%',
                '45por': '45%',
                '50por': '50%',
                '60por': '60%',
                '70por': '70%',
                '80por': '80%',
                '90por': '90%',
                '95por': '95%',
                '5rem': '5rem',
                '10rem': '10rem',
                '14rem': '14rem',
                '15rem': '15rem',
                '16rem': '16rem',
                '18rem': '18rem',
                '20rem': '20rem',
                '24rem': '24rem',
                85: '27rem',
                90: '31rem',
                // END BACKOFFICE
            },
            height: {
                // BACKOFFICE
                '10por': '10%',
                '15por': '15%',
                '20por': '20%',
                '25por': '25%',
                '30por': '30%',
                '35por': '35%',
                '40por': '40%',
                '50por': '50%',
                '60por': '60%',
                '70por': '70%',
                '80por': '80%',
                '90por': '90%',
                'bo-mobile-tbody': '50vh',
                'bo-ipad-tbody': '60vh',
                'bo-desktop-tbody': '63vh',
                'bo-row': '4.5rem',
                50: '10rem',
                '14rem': '14rem',
                '16rem': '16rem',
                72: '18rem',
                80: '20rem',
                '20rem': '20rem',
                '24rem': '24rem',
                85: '27rem',
                90: '31rem',
                94: '34rem',
                100: '43rem',
                // END BACKOFFICE
            },
            spacing: {
                '2px': '2px',
                28: '7rem',
                68: '17rem',
                72: '18rem',
                84: '21rem',
                96: '24rem',
                120: '30rem',
                14: '3.5rem',
            },
            maxWidth: {
                mobile: '520px',
                tablet: '768px',
                pro: '1024px',
            },
            maxHeight: {
                120: '30rem',
                140: '42rem',
            },
            borderRadius: {
                '2xl': '.95rem',
            },
            fontSize: {
                xxs: '10px',
                sm: '14.5px',
            },
            translate: {
                '1/10': '10%',
                '-1/10': '-10%',
                '1/6': '16.666666%',
                '-1/6': '-16.666666%',
            },
            boxShadow: {
                invert: '0 0 20px 10px rgba(0,0,0,.45)',
                btn: '0 5px 5px rgba(23,23,23,0.5)',
                bullet: '0 0 12px rgba(0,0,0,0.5)',
                module: '0 5px 12px rgba(0,0,0,0.5)',
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [
        require('autoprefixer'),
        require('@tailwindcss/custom-forms'),
        require('tailwindcss-transitions')(),
        plugin(function ({ addVariant, e }) {
            addVariant('first-of-type', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`first-of-type${separator}${className}`)}:first-of-type`;
                });
            });
        }),
        {
            cssnano: {},
        },
    ],
    purge: {
        enabled: true,
        layers: ['base', 'components', 'utilities'],
        content: ['./src/**/*.{vue,js,ts,jsx,tsx,css,scss,ttf,woff,eot}'],
        options: {
            fontFace: false,
        },
    },
};
