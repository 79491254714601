<template>
    <div class="noselect">
        <router-view />
        <toaster />
        <portal-target name="modals"></portal-target>
        <!-- <UpdateDialog /> -->
        <NotificationModal />
    </div>
</template>

<script>
    import Toaster from '@/pages/shared/toasts/Toaster';
    // import UpdateDialog from '@/pages/shared/dialogs/UpdateDialog';
    import NotificationModal from '@/pages/shared/modals/NotificationModal';
    import { actions } from '@/store';

    export default {
        name: 'App',
        components: {
            Toaster,
            // UpdateDialog,
            NotificationModal,
        },
        computed: {
            user() {
                return actions.getUser();
            },
        },
        watch: {
            user(n) {
                if (n) {
                    window.Echo.channel('user.' + this.user.data.id).listen('EmailSentEvent', e => {
                        this.$notify({ group: 'generic', title: 'Info', text: e.data.msg, type: 'info' }, this.millis);
                    });
                }
            },
        },
    };
</script>
