import '@/assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css';
import '@/assets/scss/my-sass.scss';

require('./sockets.js');

import Vue from 'vue';
import App from './App.vue';
/*import './registerServiceWorker'*/
import wb from './registerServiceWorker';
Vue.prototype.$workbox = wb;

import router from './router';

Vue.config.productionTip = false;

import Notifications from 'vt-notifications';
Vue.use(Notifications);

let dayjs = require('dayjs');
require('dayjs/locale/es');
dayjs.locale('es');
Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs });

import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
//import loadMap from 'highcharts/modules/map';

import proj4 from 'proj4';
window.proj4 = window.proj4 || proj4;

require('highcharts/modules/annotations')(Highcharts);

//loadMap(Highcharts);
Vue.use(VueHighcharts, { Highcharts });
HighchartsMore(Highcharts);
solidGauge(Highcharts);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

Highcharts.setOptions({
    lang: {
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    },
});
/*

Highcharts.Pointer.prototype.getHoverData = function (existingHoverPoint, existingHoverSeries, series, isDirectTouch, shared, e) {
    let hoverPoint,
        hoverPoints = [],
        hoverSeries = existingHoverSeries,
        useExisting = !!(isDirectTouch && existingHoverPoint),
        notSticky = hoverSeries && !hoverSeries.stickyTracking,
        filter = function (s) {
            return (
                s.visible &&
                !(!shared && s.directTouch) && // #3821
                Highcharts.pick(s.options.enableMouseTracking, true)
            );
        },
        // Which series to look in for the hover point
        searchSeries = notSticky
            ? // Only search on hovered series if it has stickyTracking false
              [hoverSeries]
            : // Filter what series to look in.
              series.filter(function (s) {
                  return filter(s) && s.stickyTracking;
              });

    // Use existing hovered point or find the one closest to coordinates.
    hoverPoint = useExisting || !e ? existingHoverPoint : this.findNearestKDPoint(searchSeries, shared, e);

    // Assign hover series
    hoverSeries = hoverPoint && hoverPoint.series;

    // If we have a hoverPoint, assign hoverPoints.
    if (hoverPoint) {
        // When tooltip is shared, it displays more than one point
        if (shared && !hoverSeries.noSharedTooltip) {
            searchSeries = series.filter(function (s) {
                return filter(s) && !s.noSharedTooltip;
            });

            // Get all points with the same x value as the hoverPoint
            searchSeries.forEach(function (s) {
                let point = Highcharts.find(s.points, function (p) {
                    return p.clientX === hoverPoint.clientX && !p.isNull;
                });

                if (Highcharts.isObject(point)) {
                    /!*
                     * Boost returns a minimal point. Convert it to a usable
                     * point for tooltip and states.
                     *!/
                    if (s.chart.isBoosting) {
                        point = s.getPoint(point);
                    }
                    hoverPoints.push(point);
                }
            });
        } else {
            hoverPoints.push(hoverPoint);
        }
    }
    return {
        hoverPoint: hoverPoint,
        hoverSeries: hoverSeries,
        hoverPoints: hoverPoints,
    };
};
*/

// Vue filters

function numberFormat(number, decimals) {
    if (!number) {
        number = 0;
    }
    return parseFloat(number)
        .toLocaleString('en', {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals,
        })
        .replace(/,/g, '@')
        .replace('.', ',')
        .replace(/@/g, '.');
}

Vue.filter('numberFormat', function (number, decimals) {
    return numberFormat(number, decimals);
});

Vue.filter('reduceBigNumbers', function (number, decimals) {
    if (number > 1000000 || number < -1000000) {
        return numberFormat(number / 1000000, decimals) + 'M';
    }

    if (number > 1000 || number < -1000) {
        return numberFormat(number / 1000, decimals) + 'K';
    }

    return numberFormat(number, decimals);
});

Vue.filter('shortDateFormat', function (date) {
    return dayjs(date).format('MMM YY');
});

Vue.filter('middleDateFormat', function (date) {
    return dayjs(date).format('D MMM YY');
});

Vue.filter('longDateFormat', function (date) {
    return dayjs(date).format('ddd D MMM YY');
});

Vue.mixin({
    data() {
        return {
            backofficeRouteNames: ['BackAreas', 'BackAreaAmounts', 'BackCourses', 'BackEditions', 'BackUsers', 'BackConnections', 'BackHpLists', 'BackNotifications'],
            emptyResultsMsg: 'No hay registros',
            selectOptionMsg: 'Selecciona una opción',
            isNotEditableMsg: 'Click en el botón Editar si quieres hacer algún cambio.',
            millis: 4000,
            boRouteNames: ['BackAreas', 'BackAreaAmounts', 'BackCourses', 'BackEditions', 'BackUsers', 'BackConnections', 'BackHpLists', 'BackNotifications','Configurations'],
            boAdminRouteNames: ['BackAreas','BackAreaAmounts',  'BackCourses', 'BackEditions', 'BackUsers', 'BackHpLists', 'BackNotifications','Configurations'],
        }
    },
    computed: {
        isFirefox() {
            let pos = navigator.userAgent.search('irefox');

            return pos >= 0;
        },
    },
    methods: {
        toastErrors(errors) {
            let errorItems = Object.keys(errors);
            for (let i = 0; i < errorItems.length; i++) {
                for (let j = 0; j < errors[errorItems[i]].length; j++) {
                    this.$notify({ group: 'generic', title: 'Error', text: errors[errorItems[i]][j], type: 'error' }, this.millis);
                }
            }
        },
        showModeNotifier() {
            this.$notify({ group: 'generic', title: 'Entrada bloqueada', text: this.isNotEditableMsg, type: 'info' }, this.millis);
        },
    },
});

import VueAnalytics from 'vue-analytics';
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
    id: 'G-XFHWZXN76C',
    router,
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
