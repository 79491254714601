import Model from "../Model";

class User extends Model {
    buildUrl ({ param }) {
        return ['users', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'users',
            data: form
        });
    }

    async getAuthUser() {
        return await this.request({
            method: 'GET',
            url: 'users/me'
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'users/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'users/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'users/destroy',
            data: form
        });
    }

    async passwordReset(form) {
        return await this.request({
            method: 'POST',
            url: 'users/password-reset',
            data: form
        });
    }

    async device(form) {
        return await this.request({
            method: 'POST',
            url: 'users/device',
            data: form
        });
    }
}

export default new User;