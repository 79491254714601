import Echo from 'laravel-echo';
import { envConfig } from '@/config/envConfig';

window.Pusher = require('pusher-js');

let encrypted = envConfig.env.VUE_APP_PUSHER_ENCRIPTED !== 'false';
let forceTLS = envConfig.env.VUE_APP_PUSHER_FORCETLS !== 'false';

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: envConfig.env.VUE_APP_PUSHER_KEY,
    cluster: 'mt1',
    wsHost: window.location.hostname,
    wsPort: envConfig.env.VUE_APP_BASE_WEBSOCKETS_PORT,
    wssPort: envConfig.env.VUE_APP_BASE_WEBSOCKETS_PORT,
    disableStats: true,
    encrypted: encrypted,
    enabledTransports: ['ws', 'wss'],
    forceTLS: forceTLS, // Critical if you want to use a non-secure WebSocket connection
    namespace: 'App.Zeus.Shared.Events',
});
